import React, { useContext, useEffect, useCallback, useState } from 'react';
import { Field } from 'formik';
import api from 'services/emeserver/api';
import authToken from 'services/emeserver/authToken';
import FormContext from 'context/FormContext';
import AlreadyRegistered from '../../RegisterLead/AlreadyRegistered';
import * as S from '../../RegisterLead/StepTwo/styles';
import { IMaskInput } from '@mirco312312/react-imask';

interface StepTwoTeamsProps {
  alreadyRegistered: boolean;
  group_dashboard?: string;
  client: {
    full_name: string;
    email: string;
    agent_id: number;
    agent_name: string;
  };
  enterprises?: any;
}

const StepTwoTeams: React.FC<StepTwoTeamsProps> = (props) => {
  const { fields, setFields } = useContext(FormContext);
  const [agents, setAgents] = useState([]);
  const [agent_id, setAgent_id] = useState(0);
  const [enterprises, setEnterprises] = useState([]);

  const { client } = props;

  const selectedAgent = JSON.parse(sessionStorage.getItem('selectedAgent'));
  const permission = sessionStorage.getItem('permission');
  const [dashboardAgent, setDashboardAgent] = useState('');

  function changeString(e, property) {
    if (property === 'agent_id') {
      setAgent_id(e.target.value);
    }
    if (property === 'group_dashboard') {
      setFields({ ...fields, [property]: e });
    }
    setFields({ ...fields, [property]: e.target.value });
  }

  async function changeDashboardEnterprise(e) {
    changeString(e, 'agent_id');
    let agentsSelect;
    if (selectedAgent.group_dashboard === 'hmgeral') {
      agentsSelect = await api.get('/agents/search/' + e.target.value, {
        headers: {
          Authorization: authToken.token,
        },
      });
      const dashboard = setDashboardAgent(
        agentsSelect.data.agents[0].group_dashboard,
      );
    }
  }

  const getAllAgents = useCallback(async () => {
    let agentsResponse;
    if (
      permission === 'admin' ||
      permission === 'admin-super' ||
      permission === 'eme_plantao'
    ) {
      agentsResponse = await api.get('/agents', {
        headers: {
          Authorization: authToken.token,
        },
      });
    } else {
      agentsResponse = await api.get('/agents/search/' + selectedAgent.id, {
        headers: {
          Authorization: authToken.token,
        },
      });
    }

    const { agents } = agentsResponse.data;

    return agents;
  }, []);

  const getEnterprises = useCallback(async () => {
    const group = props.group_dashboard
      ? props.group_dashboard
      : selectedAgent.group_dashboard;

    let enteprisesResponse;
    if (permission === 'eme_plantao') {
      enteprisesResponse = await api.get(
        '/enterprises/list/searchgroup?group_dashboard=eme_plantao',
        {
          headers: {
            Authorization: authToken.token,
          },
        },
      );
    } else {
      enteprisesResponse = await api.get(
        '/enterprises/list/searchgroup?group_dashboard=' + group,
        {
          headers: {
            Authorization: authToken.token,
          },
        },
      );
    }

    const { response_data } = enteprisesResponse.data;

    return response_data;
  }, []);

  const getEnterprisesGeral = useCallback(async (dashboard: string) => {
    let enteprisesResponse;
    if (permission === 'eme_plantao') {
      enteprisesResponse = await api.get(
        '/enterprises/list/searchgroup?group_dashboard=eme_plantao',
        {
          headers: {
            Authorization: authToken.token,
          },
        },
      );
    } else {
      enteprisesResponse = await api.get(
        '/enterprises/list/searchgroup?group_dashboard=' + dashboard,
        {
          headers: {
            Authorization: authToken.token,
          },
        },
      );
    }

    const { response_data } = enteprisesResponse.data;

    return response_data;
  }, []);

  useEffect(() => {
    async function setAllAgents() {
      const agents = await getAllAgents();

      const filterValidAgents = agents.filter(
        (agent) => agent.permission === 'corretor' && agent.activated,
      );

      const filterAgentsbyGroup = filterValidAgents.filter((agent) => {
        if (client && client.agent_id === agent.id) {
          setAgent_id(client.agent_id);
        } else {
          setAgent_id(fields.agent_id);
        }
        if (selectedAgent.group_dashboard === 'hmgeral') {
          return agent;
        } else {
          return selectedAgent.group_dashboard === agent.group_dashboard;
        }
      });

      setAgents(filterAgentsbyGroup);
    }

    async function setAllEnterprises() {
      const enteprises = await getEnterprises();

      let filterValidEnterprises;
      if (permission == 'eme_plantao') {
        filterValidEnterprises = enteprises.filter(
          (enterprise) => enterprise.slug === props.enterprises,
        );
      } else {
        filterValidEnterprises = enteprises.filter(
          (enterprise) => enterprise.slug !== '',
        );
      }

      setEnterprises(filterValidEnterprises);
    }

    async function fetch() {
      await setAllAgents();
      await setAllEnterprises();
    }

    fetch();
  }, [getAllAgents, getEnterprises]);

  useEffect(() => {
    async function setAllEnterprises() {
      const enteprises = await getEnterprisesGeral(dashboardAgent);

      let filterValidEnterprises;
      if (permission == 'eme_plantao') {
        filterValidEnterprises = enteprises.filter(
          (enterprise) => enterprise.slug === props.enterprises,
        );
      } else {
        filterValidEnterprises = enteprises.filter(
          (enterprise) => enterprise.slug !== '',
        );
      }

      setEnterprises(filterValidEnterprises);
    }

    async function fetch() {
      if (permission !== 'eme_plantao') {
        await setAllEnterprises();
      }
    }

    fetch();
  }, [dashboardAgent]);

  const handleCepChange = async (e) => {
    const cep = e.target.value.replace(/\D/g, '');

    setFields((prev) => ({ ...prev, cep }));

    if (cep.length === 8) {
      try {
        const response = await fetch(`https://viacep.com.br/ws/${cep}/json/`);
        const data = await response.json();

        if (!data.erro) {
          setFields((prev) => ({
            ...prev,
            address: {
              ...prev.address,
              street: data.logradouro || '',
              neighborhood: data.bairro || '',
              city: `${data.localidade} - ${data.uf}` || '',
              uf: data.uf || '',
            },
          }));
        }
      } catch (error) {
        console.error('Erro ao buscar o CEP:', error);
      }
    }
  };

  return (
    <S.Container>
      {props.alreadyRegistered ? (
        <AlreadyRegistered agent_name={client.agent_name} />
      ) : (
        <>
          <Field
            className="fieldStyle"
            required
            name="nome"
            placeholder="Nome"
            value={fields.full_name}
            onChange={(e) => changeString(e, 'full_name')}
          />

          <Field
            className="fieldStyle"
            required
            name="email"
            placeholder="E-mail"
            value={fields.email}
            onChange={(e) => changeString(e, 'email')}
          />

          <IMaskInput
            mask="00000-000"
            name="cep"
            required
            value={fields.address.cep}
            onAccept={(value) =>
              setFields((prev) => ({
                ...prev,
                address: {
                  ...prev.address,
                  cep: value,
                },
              }))
            }
            onBlur={handleCepChange}
            className="fieldStyle"
            placeholder="CEP"
          />

          <input
            type="text"
            name="address"
            required
            value={fields.address.street}
            onChange={(e) => changeString(e, 'address')}
            className="fieldStyle"
            placeholder="Endereço"
          />

          <input
            type="text"
            name="complement"
            value={fields.complement}
            onChange={(e) => changeString(e, 'complement')}
            className="fieldStyle"
            placeholder="Complemento"
          />

          <input
            type="text"
            name="neighborhood"
            required
            value={fields.address.neighborhood}
            onChange={(e) => changeString(e, 'neighborhood')}
            className="fieldStyle"
            placeholder="Bairro"
          />

          <input
            type="text"
            name="city"
            required
            value={fields.address.city}
            onChange={(e) => changeString(e, 'city')}
            className="fieldStyle"
            placeholder="Cidade"
          />

          <select
            name="agent"
            value={fields.agent_id}
            required
            onChange={(e) => changeDashboardEnterprise(e)}
            className="fieldStyle"
          >
            <option value="" hidden disabled>
              Corretor
            </option>
            {agents.map((agent) => (
              <option key={agent.id} value={agent.id}>
                {agent.full_name}
              </option>
            ))}
          </select>
          <select
            name="enterprise"
            value={fields.enterprise_slug}
            required
            onChange={(e) => changeString(e, 'enterprise_slug')}
            className="fieldStyle"
            disabled={
              permission === 'admin-super' && !fields.agent_id ? true : false
            }
          >
            <option
              value=""
              hidden
              disabled={
                permission === 'admin-super' && !fields.agent_id ? true : false
              }
            >
              Empreendimento
            </option>
            {enterprises.map((enterprise) => (
              <option key={enterprise.slug} value={enterprise.slug}>
                {enterprise.title}
              </option>
            ))}
          </select>

          <select
            name="call_type"
            required
            value={fields.call_type}
            onChange={(e) => changeString(e, 'call_type')}
            className="fieldStyle"
          >
            <option value="" hidden disabled>
              Tipo do atendimento
            </option>
            {['Ativo', 'Roleta', 'Online', 'Retorno', 'Telefone'].map(
              (option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ),
            )}
          </select>

          <select
            name="income"
            required
            value={fields.income}
            onChange={(e) => changeString(e, 'income')}
            className="fieldStyle"
          >
            <option value="" hidden disabled>
              Renda
            </option>
            {[
              'Até R$ 1.900,00',
              'R$ 1.901,00 a R$ 2.500,00',
              'R$ 2.501,00 a R$ 3.500,00',
              'R$ 3.501,00 a R$ 5.000,00',
              'R$ 5.001,00 a R$ 7.000,00',
              'R$ 7.001,00 a R$ 9.000,00',
              'R$ 9.001,00 a R$ 12.000,00',
              'Acima de R$ 12.000,00',
            ].map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>

          <select
            name="enterprise"
            required
            value={fields.enterprise}
            onChange={(e) => changeString(e, 'enterprise')}
            className="fieldStyle"
          >
            <option value="" hidden disabled>
              Empreendimento
            </option>
            {[
              'Intense Campos Elíseos',
              'Intense Campinas',
              'Maxi Campinas',
              'Smart Bem Morar Ouro Verde',
              'Smart Bem Morar São Jorge',
            ].map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>

          <select
            name="media"
            required
            value={fields.media}
            onChange={(e) => changeString(e, 'media')}
            className="fieldStyle"
          >
            <option value="" hidden disabled>
              Mídia
            </option>
            {[
              'TV',
              'Redes sociais',
              'Rádio',
              'Outdoor',
              'Jornal',
              'Carro de som',
              'Folheto',
              'Passagem',
              'WhatsApp',
              'Indicação',
              'Prefeitura',
              'Portais web',
              'Site',
              'Cavalete de percurso',
            ].map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>

          <select
            name="imob"
            required
            value={fields.imob}
            onChange={(e) => changeString(e, 'imob')}
            className="fieldStyle"
          >
            <option value="" hidden disabled>
              Imobiliária
            </option>
            {[
              'HM Vendas',
              'HM Vendas - Parceiro',
              'Pelegrini',
              'Madia',
              'LG Mota',
            ].map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>

          <textarea
            name="observation"
            value={fields.observation}
            onChange={(e) => changeString(e, 'observation')}
            className="fieldStyle"
            placeholder="Observação"
          />
        </>
      )}
    </S.Container>
  );
};

export default StepTwoTeams;
